<template>
  <v-hover v-slot:default="{ hover }">
    <section class="timeline">
      <div class="up" v-show="hover">
        <v-btn
          :disabled="noMoreUp" 
          color="primary"
          icon
          class="nav-button"
          small
          @click="prev"
        >
          <v-icon large>mdi-chevron-up</v-icon>
        </v-btn>
      </div>
      <div class="down" v-show="hover">
        <v-btn
          :disabled="noMoreDown"
          color="primary"
          icon
          class="nav-button"
          small
          @click="next"
        >
          <v-icon large>mdi-chevron-down</v-icon>
        </v-btn>
      </div>
      <section class="timeline-item" v-if="activeItem" :key="activeItem.id">
        <div class="item d-flex flex-column fill-height">
          <v-avatar size="25" class="dot" :class="[iconize.class]">
            <v-icon small color="white">
              {{ iconize.icon }}
            </v-icon>
          </v-avatar>
          <strong class="date-item mt-4">
            {{ activeItem.created_at | format("lll") }}
          </strong>
          <strong class="title-item">
            {{ activeItem.description | trunc_mid(40, 40) }}
          </strong>
          <div class="d-flex mt-1 align-items-center">
            <v-avatar
              v-for="attachment in splice_attachments"
              :key="attachment.id"
              tile
              class="mr-1 rounded-10 transparent"
            >
              <v-img
                v-if="attachment.has_preview && attachment.preview_image"
                :src="attachment.preview_image"
              ></v-img>
              <v-icon large v-else color="primary">mdi-image</v-icon>
            </v-avatar>
            <v-avatar
              tile
              v-show="splice_more > 0"
              class="mr-1 rounded-10 primary--text border-1 border-color--grey"
            >
              + {{ splice_more }}
            </v-avatar>
          </div>
        </div>
      </section>
    </section>
  </v-hover>
</template>

<script>
import _ from "lodash";
export default {
  name: "CustomTimeline",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    display: {
      type: Number,
      default: () => 3,
    },
  },
  data: () => ({
    activeItem: null,
  }),
  computed: {
    iconize() {
      return {
        icon:
          this.activeItem && this.activeItem.log_name === "files"
            ? "mdi-file"
            : "mdi-briefcase",
        class:
          this.activeItem && this.activeItem.log_name === "files"
            ? "primary"
            : "success",
      };
    },
    noMoreUp() {
      if (!this.activeItem) return true;
      let index = this.items.findIndex((i) => i.id === this.activeItem.id);
      return ~index ? index === 0 : false;
    },
    noMoreDown() {
      if (!this.activeItem) return true;
      let index = this.items.findIndex((i) => i.id === this.activeItem.id);
      return ~index ? index === this.items.length - 1 : false;
    },
    splice_attachments() {
      return this.activeItem && this.activeItem.attachments.length > 0
        ? _.cloneDeep(this.activeItem.attachments).slice(0, this.display)
        : [];
    },
    splice_more() {
      let length = this.activeItem.attachments.length || 0;
      return length > this.display ? length - this.display : 0;
    },
  },
  watch: {
    items: {
      handler: function (val) {
        if (val && val.length) {
          this.activeItem = val[0];
        } else {
          this.activeItem = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    prev() {
      let index = this.items.findIndex((i) => i.id === this.activeItem.id);
      if (~index) this.activeItem = this.items[index - 1];
    },
    next() {
      let index = this.items.findIndex((i) => i.id === this.activeItem.id);
      if (~index) this.activeItem = this.items[index + 1];
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  height: 200px;
  overflow-y: visible;
  overflow-x: visible;
  .up {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 10px;
    z-index: 3;
  }
  .down {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3;
    margin-bottom: 10px;
  }
  .timeline-item {
    height: 100%;
    .item {
      border-left: 3px solid #e0e0e0;
      padding: 5px 0 5px 25px;
      position: relative;
    }
    .date-item {
      margin: 0;
      font-size: 15px;
      font-weight: 900;
      color: $blue;
      letter-spacing: 1px;
    }
    .title-item {
      display: block;
      margin: 0;
      font-size: 12px;
      color: $blue;
      font-weight: 500;
    }
    .dot {
      display: block;
      position: absolute;
      border-radius: 50%;
      left: -15px;
      top: 20px;
    }
  }
}
</style>