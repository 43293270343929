<template>
  <v-hover v-slot:default="{ hover }">
    <section class="task">
      <div class="up" v-show="hover">
        <v-btn
          :disabled="noMoreUp"
          color="primary"
          icon
          class="nav-button"
          small
          @click="prev"
        >
          <v-icon large>mdi-chevron-up</v-icon>
        </v-btn>
      </div>
      <div class="down" v-show="hover">
        <v-btn
          :disabled="noMoreDown"
          color="primary"
          icon
          class="nav-button"
          small
          @click="next"
        >
          <v-icon large>mdi-chevron-down</v-icon>
        </v-btn>
      </div>
      <section
        class="task-item d-flex justify-center align-center fullwidth"
        v-if="activeItem"
        :key="activeItem.id"
      >
        <div class="item d-flex flex-column align-start justify-center">
          <strong class="date-item fs-18">
            {{ activeItem.title | ucwords | trunc_mid(40, 40) }}
          </strong>
          <strong class="title-item subtitle-2">
            {{ activeItem.created_at | format('ddd, D MMMM, YYYY') }}
            <v-chip
              pill
              class="ml-3 caption px-5 light--text"
              :color="activeItem.status.toLowerCase()"
              x-small
            >
              {{ activeItem.status | ucwords }}
            </v-chip>
          </strong>
          <strong v-if="activeItem.project" class="primary--text">
            Project :
            <span class="cursor-pointer" @click="navigateProject">
              {{ activeItem.project.title }}
            </span>
          </strong>
          <div class="d-flex mt-1 align-items-center">
            <Avatars
              v-if="activeItem.assigned.length"
              :items="activeItem.assigned"
            ></Avatars>
          </div>
        </div>
      </section>
    </section>
  </v-hover>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'CustomTimeline',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    display: {
      type: Number,
      default: () => 3
    }
  },
  data: () => ({
    activeItem: null
  }),
  mounted() {
    if (this.items && this.items.length > 1) {
      let i = 0
      var _this = this
      setInterval(function () {
        if (i === _this.items.length) {
          i = 0
        } else i++
        _this.activeItem = _this.items[i]
      }, 5000)
    }
  },
  computed: {
    noMoreUp() {
      if (!this.activeItem) return true
      let index = this.items.findIndex((i) => i.id === this.activeItem.id)
      return ~index ? index === 0 : false
    },
    noMoreDown() {
      if (!this.activeItem) return true
      let index = this.items.findIndex((i) => i.id === this.activeItem.id)
      return ~index ? index === this.items.length - 1 : false
    }
  },
  watch: {
    items: {
      handler: function (val) {
        if (val && val.length) {
          this.activeItem = val[0]
        } else {
          this.activeItem = null
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    prev() {
      let index = this.items.findIndex((i) => i.id === this.activeItem.id)
      if (~index) this.activeItem = this.items[index - 1]
    },
    next() {
      let index = this.items.findIndex((i) => i.id === this.activeItem.id)
      if (~index) this.activeItem = this.items[index + 1]
    },
    navigateProject() {
      this.$router
        .push({
          name: 'hq',
          params: { id: this.activeItem.project.id, type: 'project' }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.task {
  overflow-y: visible;
  overflow-x: visible;
  .up {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 10px;
    z-index: 3;
  }
  .down {
    position: absolute;
    right: 0;
    margin-bottom: 10px;
    bottom: 0;
    z-index: 3;
  }
  .task-item {
    height: 100%;
    .item {
      padding: 5px;
      position: relative;
    }
    .date-item {
      margin: 0;
      font-size: 15px;
      font-weight: 900;
      color: $blue;
      letter-spacing: 1px;
    }
    .title-item {
      display: block;
      margin: 0;
      font-size: 12px;
      color: $blue;
      font-weight: 500;
    }
  }
}
</style>
